import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaUserDoctor } from "react-icons/fa6";
import Loader from "../components/loader";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const apiLink = process.env.REACT_APP_API_LINK;

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const username = useRef(null);
  const pass = useRef(null);



  async function handleLogin(e) {
    e.preventDefault();
    const obj = {
      action: "LOG_IN",
      userName: username.current.value,
      password: pass.current.value,
    };
    try {
      setLoading(true);
      await axios.post(apiLink + "test.php", obj).then((res) => {
        setLoading(false);
        if (res.data.success == true) {
          const userCach = {
            user: username.current.value,
            pass: pass.current.value,
          };
          Cookies.set("usercach", JSON.stringify(userCach));
          history("/", { replace: true });
        } else {
        }
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  return (
    <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
      <div className="w-1/3  relative  max-w-sm">
        <div className=" w-full h-full  bg-[#46B59B] p-5 rounded-full">
          <FaUserDoctor className=" w-full h-full text-white" />
        </div>
      </div>
      <form className="md:w-1/3 max-w-sm">
        <input
          ref={username}
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
          type="text"
          placeholder="Email Address"
        />
        <input
          ref={pass}
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="password"
          placeholder="Password"
        />

        <div className="text-center md:text-left">
          <button
            onClick={(e) => handleLogin(e)}
            className="mt-4 w-full bg-[#46B59B] px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
            type="submit"
          >
            {loading ? <Loader /> : "Login"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default Login;
