

import axios from 'axios';
import Header from '../components/header';
import PatientCard from '../components/patientCard';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import apiLink from '../urls';
import Loader from '../components/loader';
import { PatientContext } from '../contexts/patientsContext';

const Home = () => {
  const apiLink = process.env.REACT_APP_API_LINK;

  const [state,dispatch] = useContext(PatientContext);
const [page,setPage] = useState(1)
 const [loading,setLoading] = useState(false);
 const observer = useRef(null);
 const patienList = useRef([])

 const lastElementRef = useCallback(
  (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        entries[0].intersectionRatio > 0 &&
        entries[0].intersectionRatio < 1 
      ) {
        setPage((prevPage) => prevPage + 1);
        // setIsLoading(true);
      }
    });
    if (node) observer.current.observe(node);
  },
  [loading]
);


const PatientsList = useMemo(() => {
  
  return patienList.current.map((patient, index) => {
    if (patienList.current.length === index + 1) {
       return (
       <div ref={lastElementRef}> <PatientCard  ispinned={patient.ispinned} date={patient.date_add} name={patient.name} id={patient.id}   phone={patient.phone}/></div>
       )
    }else{
      return(
<PatientCard ispinned={patient.ispinned} date={patient.date_add} name={patient.name} id={patient.id}   phone={patient.phone}/>
      )
  }



  })
  },[lastElementRef])

useEffect(()=>{
  setLoading(true)
   axios.get(apiLink+`webapi.php?start=${page}`).then((res)=>{
    setLoading(false)
  patienList.current = [
    ...patienList.current,
    ...res?.data.patients,
  ];
})
},[page])
 

    //  useEffect(()=>{
    //   if(state.reloadPatients){
    //   getPatients();
    //   }
    //  },[state.reloadPatients,page])
 
  // async function getPatients(){
  //   setLoading(true)
  //   await axios.get(apiLink+`webapi.php?start=${page}`).then((res)=>{
  //     setLoading(false)
  //     dispatch({type:"setListPatients",payload: res.data.patients})
  //     dispatch({type:"setReloadPatients",payload: false})
 
  //   });
  
  // }
  // useEffect(()=>{
    
  //   getPatients();
  
  // },[])






  return (
    <>
      <Header/>
   <div  className='flex flex-col h-full  divide-y-2 w-full container mx-auto'>
 {loading&&
 <div className='fixed  flex justify-center text-center z-40 w-screen h-screen top-0 left-0 bottom-0 right-0'>
 <div className='  h-fit my-auto'>
<Loader/>
    </div>
</div>
}

{/* { state.listPatients&& state.listPatients.map((patient)=>{
  return<><PatientCard ispinned={patient.ispinned} date={patient.date_add} name={patient.name} id={patient.id}   phone={patient.phone}/></>
})} */}


{PatientsList}


  

   </div>
   </>
  )
}

export default Home