import React, { useContext, useEffect } from 'react'
import { FaUserPlus } from 'react-icons/fa'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'

import Loader from './loader'
import {  useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

const AddPatient = () => {
    const [open, setOpen] = useState(false)
const [loading ,setLoading] = useState(false)
const apiLink = process.env.REACT_APP_API_LINK;

   
    const nameRef = useRef(null)
    const phoneRef = useRef(null)
     const history = useNavigate();
    const cancelButtonRef = useRef(null)
    const  addPatient=async(e)=>{
      const currentDate = new Date();

      // Format the date as 'dd/MM/yy'
      const formattedDate = format(currentDate, 'yy-MM-dd');
      e.preventDefault();
      setLoading(true)
      const obj = {
        action: "ADD_PATIENT",
        name:nameRef.current.value,
        phone:phoneRef.current.value,
        date:formattedDate
      };
      console.log(obj)
      const tempname = nameRef.current.value;
      const tempphone = phoneRef.current.value;
      try{
      await axios.post(apiLink+"test.php",obj
      ).then((res)=>{

        console.log(res)
        setLoading(false)
        history(`/${res.data.id}`,{state:{
          name:tempname,
          phone:tempphone,
          id:res.data.id
        }})
        setOpen(false);
      
      })
    }catch(e){
      setLoading(false)
      console.log(e);
    }
    }
  return (
    <div>
         <button onClick={()=> setOpen(true)}>
      <FaUserPlus />
      </button>




<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
               <form onSubmit={(e)=>addPatient(e)}>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex  sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  bg-[#46b59b5d] sm:mx-0 sm:h-10 sm:w-10">
                      <FaUserPlus className="h-6 w-6 text-[#46B59B]" aria-hidden="true" />
                    </div>
                    <div className="mt-3   w-full relative overflow-hidden  sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                       Add Patient Info
                      </Dialog.Title>
                      <div className=' px-9 border-dashed h-1 border-t-2 '></div>
                      <div className="mt-4">
                      <div className="">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-500">
                Patient Name
              </label>
              <div className="mt-2">
                <input
                ref={nameRef}
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block  w-full transition-all rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:bg-blue-200 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
                      </div>

<div className='w-screen '></div>
                      <div className="mt-2 ">
                      <div className=" ">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-500">
                Patient Phone
              </label>
              <div className="mt-2 ">
                <input
                ref={phoneRef}
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block w-full  transition-all rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 outline-none focus:ring-inset focus:bg-blue-200 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                 
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
               
                  <button
                    type="submit"
                   
                    className="inline-flex w-full justify-center mt-3  rounded-md  bg-[#46B59B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#46B59B] sm:ml-3 sm:w-auto"
                    
                  >
                    {loading?<Loader/>:"ADD & Enter"}

                  </button>
                </div>


                </form>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  )
}

export default AddPatient